const $ = require("jquery");
window.$ = $;
window.jQuery = $;

const CSS_URL = 'https://fonts.googleapis.com/earlyaccess/notosansjp.css';
l = document.createElement('link');
l.rel='stylesheet';
l.href = CSS_URL;
document.head.appendChild(l);

require('./modules/_spMenu')
require('./modules/_toTop')
require('./modules/_smoothScrool')
require('./modules/_popup')
require('./modules/_lazyload')
