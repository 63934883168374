
const navBtn = $('.l-globalNav_btn')
const nav = $('.l-globalNav')
const body = $('body')
navBtn.on('click',function(){
  if(nav.is(':hidden') && !nav.is(':animated')){
     nav.fadeIn()
     navBtn.addClass('is-open')
     nav.prepend('<div class="close"></div>')
     body.addClass('no-scroll')
     body.on('touchmove.noScroll', function(e) {
       e.preventDefault()
     })
   }else{
      nav.fadeOut()
      $('.close').remove()
      navBtn.removeClass('is-open')
      body.removeClass('no-scroll')
      body.off('.noScroll')
   }
});
$(document).on('click','.close',function(){
    nav.fadeOut()
    navBtn.removeClass('is-open')
    $('.close').remove()
    body.removeClass('no-scroll')
    body.on('touchmove.noScroll', function(e) {
      e.preventDefault()
    })
});
